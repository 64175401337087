import React from "react"

const Contact = () => {
  return (
    <main>
      <h1 className="bg-sky-500 text-stone-50">
        Contact Us
      </h1>

    </main>
  )
}

export default Contact